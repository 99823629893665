// Praegune lahendus on liiga lihtne. Kui aplikatsioon valmis, siis ümber teha.
// https://codeburst.io/vuex-store-d888de10d499
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    screenWidth: null,
    screenHeight: null,
    reloadKey: 12345999 // to reload views according to width changes
  },
  getters: {
    screenWidth: state => state.screenWidth,
    screenHeight: state => state.screenHeight,
    reloadKey: state => state.reloadKey
  },
  mutations: {
    SET_SCREENWIDTH: (state, newValue) => {
      state.screenWidth = newValue;
    },
    SET_SCREENHEIGHT: (state, newValue) => {
      state.screenHeight = newValue;
    },
    SET_RELOADKEY: (state, newValue) => {
      state.reloadKey = newValue;
    }
  },
  actions: {
    setScreenWidth: ({ commit, state }, newValue) => {
      commit("SET_SCREENWIDTH", newValue);
      return state.screenWidth;
    },
    setScreenHeight: ({ commit, state }, newValue) => {
      commit("SET_SCREENHEIGHT", newValue);
      return state.screenHeight;
    },
    setReloadKey: ({ commit, state }, newValue) => {
      commit("SET_RELOADKEY", newValue);
      return state.reloadKey;
    }
  }
});
