<template>
  <v-app>
    <home-app-bar />

    <home-view />

    <home-footer />
  </v-app>
</template>

<script>
import { store } from "../../store/index.js";

export default {
  name: "HomeLayout",
  store,
  components: {
    HomeAppBar: () => import("@/layouts/home/AppBar"),
    HomeFooter: () => import("@/layouts/home/Footer"),
    HomeView: () => import("@/layouts/home/View"),
  },
};
</script>
